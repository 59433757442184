<template>
  <div class="allTemplate">
    <div class="searchflex">
      <div class="searchLeft">
        <div class="searchInput">
          <el-select
            size="mini"
            v-model="products"
            @change="getSearch()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in productsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="searchInput">
          <el-input
            size="mini"
            v-model="search"
            @input="getSearch()"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
      </div>
      <div class="searchRight">
        <img src="@/assets/image/add_new.png" class="addIcon" @click="add()" />
      </div>
    </div>
    <yh-table
      tableBorder
      :tableHeight="tableHeight"
      :tableLoading="tableLoading"
      :tableHead="tableHead"
      :tableData="tableData"
      :tableBind="tableBind"
    >
      <template #tableRow="{ row, item }">
        <span v-if="item.prop == 'type'">{{
          row[item.prop] | typeFilter
        }}</span>
        <span v-else>{{ row[item.prop] }}</span>
      </template>
      <el-table-column label="操作" width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <yh-popover
            popoverTitle="修改"
            @savePopUp="edit(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverOnly="1"
            popoverTitle="删除"
            @savePopUp="del(scope.row)"
          >
            确定删除该信息吗？
          </yh-popover>
        </template>
      </el-table-column>
    </yh-table>

    <!-- 新增编辑 -->
    <yh-dialog :dialogObj="dialogObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="formRule"
        :formHead="formHead"
        :formData="formData"
      ></yh-form>
      <template #footer>
        <el-button size="mini" type="primary" v-if="prepare" @click="submit()">
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
  </div>
</template>
<script>
import { listRecursion } from "@/utils/convert";
export default {
  filters: {
    typeFilter: function (value) {
      switch (value) {
        case "MENU":
          return "菜单";
        case "BUTTON":
          return "按钮";
        default:
          return "";
      }
    },
  },
  data() {
    return {
      products: "",
      productsList: [],
      search: "",
      // 表格
      tableLoading: false,
      tableHeight: "100px",
      tableBind: {
        rowKey: "id",
        treeProps: { children: "children", hasChildren: "hasChildren" },
      },
      tableHead: [
        {
          prop: "name",
          label: "名称",
          fixed: false,
          minWidth: "120",
        },
        {
          prop: "icon",
          label: "图标",
          fixed: false,
          minWidth: "130",
        },
        {
          prop: "path",
          label: "路径",
          fixed: false,
          minWidth: "120",
        },
        {
          prop: "orderId",
          label: "排序",
          fixed: false,
          minWidth: "130",
        },
        {
          prop: "type",
          label: "类型",
          fixed: false,
          minWidth: "130",
        },
      ],
      tableList: [],
      tableData: [],
      dialogObj: {
        dialogTitle: "新增",
        dialogOpen: false,
      },
      formRule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        productId: [
          { required: true, message: "请选择所属产品", trigger: "change" },
        ],
      },
      formHead: [
        {
          comp: "el-select",
          sonComp: "el-option",
          label: "所属产品",
          prop: "productId",
          widthSize: 2,
          bind: { disabled: true },
          data: [],
        },
        {
          comp: "el-select",
          sonComp: "el-option",
          label: "上级名称",
          prop: "parentId",
          widthSize: 2,
          bind: { clearable: true },
          data: [],
        },
        {
          comp: "el-input",
          label: "名称",
          prop: "name",
          widthSize: 2,
        },
        {
          comp: "el-select",
          sonComp: "el-option",
          label: "类型",
          prop: "type",
          widthSize: 2,
          data: [
            {
              name: "菜单",
              id: "MENU",
            },
            {
              name: "按钮",
              id: "BUTTON",
            },
          ],
        },
        {
          comp: "el-input",
          label: "图标",
          prop: "icon",
          widthSize: 2,
        },
        {
          comp: "el-input",
          label: "路径",
          prop: "path",
          widthSize: 2,
        },
        {
          comp: "el-input",
          label: "排序",
          prop: "orderId",
          widthSize: 2,
        },
      ],
      formData: {
        id: undefined,
        name: undefined,
        parentId: undefined,
        icon: undefined,
        path: undefined,
        orderId: undefined,
        type: undefined,
        productId: undefined,
      },
      prepare: true,
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    // 表格
    getSearch() {
      this.page = 1;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      this.$axios.get(
        "menus",
        {
          q: this.search,
          productId: this.products,
        },
        (res) => {
          this.tableLoading = false;
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.tableList = res.data;
            this.tableData = listRecursion(res.data);
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    // 新增
    add() {
      this.formData = {
        id: undefined,
        name: undefined,
        parentId: undefined,
        icon: undefined,
        path: undefined,
        orderId: undefined,
        type: undefined,
        productId: this.products,
      };
      this.formHead[1].data = this.tableList;
      this.dialogObj.dialogOpen = true;
      this.dialogObj.dialogTitle = "新增";
    },
    edit(row) {
      this.$axios.get("menusDetail", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.formData = res.data;
          this.formHead[1].data = this.tableList;
          this.dialogObj.dialogOpen = true;
          this.dialogObj.dialogTitle = "修改";
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    submit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          this.prepare = false;
          if (this.formData.id) {
            this.$axios.jsonPost(
              "menusEdit",
              {
                id: this.formData.id,
                name: this.formData.name,
                parentId: this.formData.parentId
                  ? this.formData.parentId
                  : null,
                icon: this.formData.icon,
                path: this.formData.path,
                orderId: this.formData.orderId,
                type: this.formData.type,
                productId: this.formData.productId,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("修改成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          } else {
            this.$axios.jsonPost(
              "menus",
              {
                name: this.formData.name,
                parentId: this.formData.parentId
                  ? this.formData.parentId
                  : null,
                icon: this.formData.icon,
                path: this.formData.path,
                orderId: this.formData.orderId,
                type: this.formData.type,
                productId: this.formData.productId,
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("添加成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    resetForm() {
      if (this.$refs.formName) {
        this.$refs.formName.resetFields();
      }
    },
    del(row) {
      this.$axios.jsonPost("menusDel", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getProduct() {
      this.$axios.get("products", {}, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.productsList = res.data;
          this.formHead[0].data = res.data;
          if (this.productsList.length > 0) {
            this.products = this.productsList[0].id;
            this.getData();
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getHeight() {
      this.tableHeight = window.innerHeight - (50 + 40 + 60 + 30) + "px";
    },
  },
};
</script>
<style scoped></style>
