// 列表变级联
export function listRecursion(list) {
  let idStr = "id";
  let pidStr = "parentId";
  let childrenStr = "children";

  let listOjb = {}; // 用来储存{key: obj}格式的对象
  let treeList = []; // 用来储存最终树形结构数据的数组

  // 将数据变换成{key: obj}格式，方便下面处理数据
  for (let i = 0; i < list.length; i++) {
    listOjb[list[i][idStr]] = list[i];
  }
  for (let j = 0; j < list.length; j++) {
    // 判断父级是否存在
    let haveParent = listOjb[list[j][pidStr]];
    if (haveParent) {
      // 如果有没有父级children字段，就创建一个children字段
      !haveParent[childrenStr] && (haveParent[childrenStr] = []);
      // 在父级里插入子项
      haveParent[childrenStr].push(list[j]);
    } else {
      // 如果没有父级直接插入到最外层
      treeList.push(list[j]);
    }
  }
  return treeList;
}
